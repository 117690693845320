<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12" style="margin:40px;text-align: left;">
        <h1>Residenciales</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="grid-container">
          <div class="grid-item" v-for="item in items" :key="item.title">
            <img :src="item.image" alt="" />
            <h5>{{ item.title }}</h5>
            <router-link :to="item.link">
              <button  class="btn btn-primary see_more_action">Ver más</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import InfiniteLoading from 'vue-infinite-loading';
export default {
  /*components: {
    InfiniteLoading
  }, */
  name: "Gallery",
  data: () => ({
    items: [
      {
        title: "Residencial Acrópoli",
        icon: "fas fa-home",
        link: "/acropoli",
        image:
          "https://acropolisonsonate.com//wp-content/uploads/2022/02/logo.png",
      },
      {
        title: "Ciudad Marsella",
        icon: "fas fa-home",
        link: "/marsella",
        image:
          "https://ciudadmarsella.com/wp-content/uploads/2021/08/Logo-transparencia-06.png",
      },
      {
        title: "Ciudad Real",
        icon: "fas fa-home",
        link: "/ciudadreal",
        image:
          "https://ciudadrealsantaanasv.com/wp-content/uploads/2019/05/ciudad-real-logo.png",
      },
      {
        title: "Haciendas de la Rivera",
        icon: "fas fa-home",
        link: "/haciendas",
        image:
          "https://dive.digital/clients/haciendas-riviera/wp-content/uploads/2020/10/logo1.png",
      },
      {
        title: "Metropoli San Gabriel",
        icon: "fas fa-home",
        link: "/metropoli",
        image:
          "http://metropoli-sangabriel.com/wp-content/uploads/2017/06/logo-metropolis-san-gabriel6.png",
      },
      {
        title: "Quintas San Antonio",
        icon: "fas fa-home",
        link: "/quintas",
        image:
          "https://quintassanantonio.com/wp-content/uploads/2019/07/logo-quintas.jpg",
      },
      {
        title: "Ecoterra Maquilishuat",
        icon: "fas fa-home",
        link: "/ecoterra",
        image:
          "https://inversionesfenix.com.sv/ecoterra-maquilishuat/wp-content/uploads/2022/05/logo-recortado.png",
      },
      {
        title: "Nueva San Miguel",
        icon: "fas fa-home",
        link: "/nuevasanmiguel",
        image:
          "https://globalelsalvador.com/NuevaSanMiguel/images/logomain.png",
      },
      {
        title: "Haciendas del mediterraneo",
        icon: "fas fa-home",
        link: "/haciendasmediterraneo",
        image:
          "https://inversionesfenix.com.sv/haciendas-del-mediterraneo/images/logomain.png",
      },
      {
        title: "Global Developers",
        icon: "fas fa-home",
        link: "/global",
        image: "https://globalelsalvador.com/static/media/logo.6f797265.png",
      },
      {
        title: "Salazar Romero",
        icon: "fas fa-home",
        link: "/salazar",
        image:
          "https://salazarromero.com/wp-content/uploads/2021/11/Grupo-198.png",
      },
      {
        title: "Villapanamericana",
        icon: "fas fa-home",
        link: "/villapanamericana",
        image:
          "https://salazarromero.com/wp-content/uploads/2021/11/Grupo-198.png",
      },
      {
        title: "Inversiones Fenix",
        icon: "fas fa-home",
        link: "/fenix",
        image: "https://inversionesfenix.com.sv/ecoterra-maquilishuat/wp-content/uploads/2022/05/logo-fenix.png"
      },
      {
        title: "Cluster La Ilusión",
        icon: "fas fa-home",
        link: "/la-ilusion",
        image: "http://clusterlailusion.com/wp-content/uploads/2021/07/logofooter.png"
      },
    ],
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
  grid-gap: 15px;
  padding: 15px;
}
.grid-item {
  background: #5b7dcf;
  font-size: 30px;
  text-align: center;
  border-radius: 20px;
  color: white;
  cursor: pointer;
}
.grid-item > div {
  padding: 10px;
}
.see_more_action {
  text-decoration: none !important;
  color: white;
  font-size: 18px;
  margin-bottom: 15px;
}
.grid-item > img {
  width: 135px;
  max-width: 100%;
  padding: 20px;
  height: 100px;
  max-height: 100%;
  object-fit: contain;
}
.grid-item > h3 {
  font-size: 22px;
}
.grid-item > span {
  font-size: 15px;
}
.grid-item:hover {
  background: #002365;
  color: white;
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
