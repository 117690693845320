<template>
  <div>
    <Navbar></Navbar>

    <br /><br />

    <div class="container">
      <div class="row" style="margin-bottom:10px;">
        <!--Return button-->
        <div class="col-lg-12">
          <router-link to="/home">
            <button style="color: white;float: left;" class="btn btn-primary">
              <span>
                <i class="fas fa-arrow-left" style="color:white;font-size:20px;margin-right: 5px;"></i>
                Regresar
              </span>
            </button>
          </router-link>
        </div>
        <!--Title-->
        <div class="col-lg-12" style="margin-top:20px;">
          <h1 style="text-align:left;">Ciudad Marsella</h1>
        </div>
      </div>
      <br />

      <div class="row">
        <!--Input dates-->
        <div class="col-lg-9"></div>
        <div class="col-lg-3"></div>
        <h6 class="text-start">Filtrar por rangos de fechas:</h6>
        <!--Start date-->
        <div class="col-lg-3">
          <b-form-group> </b-form-group>
          <b-form-datepicker
            placeholder="Fecha inicial"
            v-model="fecha_inicio"
            id="ex-disabled-readonly-inicial"
          ></b-form-datepicker>
        </div>
        <!--End date-->
        <div class="col-lg-3">
          <b-form-group> </b-form-group>
          <b-form-datepicker
            placeholder="Fecha final"
            v-model="fecha_final"
            id="ex-disabled-readonly-final"
          >
          </b-form-datepicker>
        </div>
        <!--Btn search-->
        <div class="col-lg-2 text-start">
          <button
            v-on:click="search"
            class="btn btn-secondary"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Filtrar las fechas"
            :disabled="disabledFilter"
          >
            <i class="fas fa-filter"></i>
          </button>
          <!--Btn refresh-->
          <button
            style="margin-left: 5px"
            v-on:click="clear"
            class="btn btn-warning"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Reiniciar filtros"
            :disabled="disabledFilter"
          >
            <i class="fas fa-redo"></i>
          </button>
        </div>
        <div class="col-lg-2"></div>
        <!--reports-->
        <div class="col-lg-2 text-end">
          <button
            @click="downloadExcel()"
            class="btn btn-outline-success"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Exportar en formato excel"
            :disabled="disabledFilter"
          >
            <i class="fas fa-file-excel"></i> Exportar
          </button>
        </div>
      </div>
      <br />
      <!--Table-->
      <b-table responsive  striped hover :items="paginatedItems" :fields="fields"></b-table>
      <!--Loading-->
      <div v-if="paginatedItems == '' & !loading">
        <p class="text-center">No se ha encontrado ningún registro</p>
      </div>
      <div>
        <b-spinner v-if="loading"></b-spinner>
      </div>
      <br />

      <b-col md="6" class="my-1">
        <b-pagination
          @change="onPageChanged"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
        />
      </b-col>

      <br /><br />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "../components/NavBar.vue";

export default {
  components: {
    Navbar,
  },
  name: "CiudadReal",

  data() {
    return {
      fecha_inicio: "",
      fecha_final: "",
      items: [],
      paginatedItems: [],
      currentPage: 1,
      perPage: 15,
      totalRows: "",
      loading: false,
      //Con fields se indica cuáles campos de todos los que trae el arreglo se quieren mostrar y en qué orden
      fields: [
       "nombre",
        "correo",
        "teléfono",
        "ingresos",
        "comentario",
        "fecha_registro",
      ],
    };
  },
  computed: {
    disabledData(){
      return this.paginatedItems == '';
    },
    disabledFilter(){
      let fecha1 = !this.fecha_inicio;
      let fecha2 = !this.fecha_final; 
      return fecha1, fecha2;
    }
  },
  methods: {
    downloadExcel() {
      axios
        .get("https://administracion-sitios.constru-services.com/backend/public/api/marsella/export"
         + "?" + "start_date="+this.fecha_inicio + "&" + "end_date="+this.fecha_final, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          let acropoli = response.data;
          let blob = new Blob([acropoli], { type: "application/xlsx" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "marsella" + "-" + this.fecha_inicio + "-" + this.fecha_final +".xlsx";
          link.click();
        });
    },
    paginate(page_size, page_number) {
      let itemsToParse = this.items;
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
    },
    search() {
      this.loading = true;

      if(this.fecha_inicio == "" || this.fecha_final == ""){
        axios.get("https://administracion-sitios.constru-services.com/backend/public/api/marsella")
        .then((response) => {
          this.loading = false;
          let marsella = response.data.data;
          if (marsella.length > 0) {
            this.totalRows = marsella.length;
            this.paginatedItems = marsella;
            this.items = marsella;
            this.paginate(15, 0);
          } else {
            console.log("Error en la api");
          }
        });
      }else{
        axios.get(
          "https://administracion-sitios.constru-services.com/backend/public/api/marsella/" +
            this.fecha_inicio +
            "/" +
            this.fecha_final
        )
        .then((response) => {
          let marsella = response.data.data;
          this.loading = false;
          if (marsella.length > 0) {
            this.totalRows = marsella.length;
            this.paginatedItems = marsella;
            this.items = marsella;
            this.paginate(15, 0);
          } else {
            this.totalRows = "";
            this.paginatedItems = [];
            this.items = [];
            console.log("No se encontraron resultados");
          }
        });
      }
      
    },
    clear() {
      this.fecha_inicio = "";
      this.fecha_final = "";
      this.loading = true;
      axios.get("https://administracion-sitios.constru-services.com/backend/public/api/marsella")
        .then((response) => {
          this.loading = false;
          let marsella = response.data.data;
          if (marsella.length > 0) {
            this.totalRows = marsella.length;
            this.paginatedItems = marsella;
            this.items = marsella;
            this.paginate(15, 0);
          } else {
            console.log("Error en la api");
          }
        });
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    if (token) {
      this.loading = true;
      axios.get("https://administracion-sitios.constru-services.com/backend/public/api/marsella")
        .then((response) => {
          this.loading = false;
          let marsella = response.data.data;
          if (marsella.length > 0) {
            this.totalRows = marsella.length;
            this.paginatedItems = marsella;
            this.items = marsella;
            this.paginate(15, 0);
          } else {
            console.log("Error en la api");
          }
        });
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 15px;
}
</style>