<template>
  <div>
   <NavBar></NavBar>
        <br /><br />

        <div class="container">
        <div class="row" style="margin-bottom:10px;">
            <!--Return button-->
            <div class="col-lg-12">
            </div>
            <!--Title-->
            <div class="col-lg-12" style="margin-top:20px;">
            <h1 style="text-align:left;">Listado de usuarios</h1>
            </div>
        </div>
        <br />
        <div class="container" style="display:flex; justify-content:flex-start">
            <div class="row">
                <div class="col-12">
                    <b-button variant="primary" @click="showModal">Nuevo usuario</b-button>
                </div>
            </div>
        </div><br>
       <table class="table table-bordered">
        <thead>
            <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Correo</th>
            <th scope="col">Rol</th>
            <th scope="col" colspan="2">Acciones</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="it in items" :key="it.id">
                <td>{{ it.name }}</td>
                <td>{{ it.email }}</td>
                <span v-for="r in it.rol" :key="r.id">
                    <td>{{ r.name }}</td>
                </span>
                <td>
                    <button @click="updateUser(it)" class="btn btn-outline-success m-1"><i class="fas fa-pencil-alt"></i></button>
                    <button @click="showMsgBoxOne(it)" class="btn btn-danger m-1"><i class="fas fa-trash-alt"></i></button>
                </td>
            </tr>
        </tbody>
        </table>
        <br />
        <!--Loading-->
        <div v-if="paginatedItems == '' & !loading">
            <p class="text-center">No se ha encontrado ningún registro</p>
        </div>
        <div>
            <b-spinner v-if="loading"></b-spinner>
        </div>
        <br>
        </div>
        <div>
            <!--Store modal-->
            <b-modal id="modal-1" hide-footer title="Nuevo usuario">
                <form class="p-3" autocomplete="off">
                    <b-form-group label="Nombre">
                    <b-form-input
                        v-model="name"
                        required
                        class="mb-2"
                    ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Correo:">
                        <b-form-input
                            v-model="email"
                            required
                            class="mb-2"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Contraseña:">
                        <b-form-input
                            v-model="password"
                            type="password"
                            required
                            class="mb-2"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Seleccione un rol:">
                        <b-form-select v-model="rol_id" class="mb-3 form-control">
                            <b-form-select-option v-for="item in itemRoles" :key="item.id" :value="item.id">{{item.name}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group><br>
                    <div style="display:flex;justify-content:flex-end">
                        <b-button class="m-1" variant="secondary" @click="hideModal">Cancelar</b-button>
                        <b-button class="m-1" variant="success" @click="storeUser">Guardar usuario</b-button>
                    </div>
                </form>
            </b-modal>
            <!--Update modal-->
            <b-modal id="modal-update" hide-footer title="Editar usuario">
                <form class="p-3">
                    <b-form-group label="Nombre:">
                    <b-form-input
                        v-model="update.name"
                        required
                        class="mb-2"
                    ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Correo:">
                        <b-form-input
                            v-model="update.email"
                            required
                            class="mb-2"
                        ></b-form-input>
                    </b-form-group>
                    {{ update.rol_id }}
                    <b-form-group label="Seleccione un rol:">
                        <b-form-select v-model="update.rol_id" class="mb-3 form-control">
                            <b-form-select-option v-for="item in itemRoles" :key="item.id" :value="item.id">{{item.name}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group><br>
                    <div style="display:flex;justify-content:flex-end">
                        <b-button class="m-1" variant="secondary" @click="hideModalUpdate">Cancelar</b-button>
                        <b-button class="m-1" variant="success" @click="updateData">Actualizar usuario</b-button>
                    </div>
                </form>
            </b-modal>
            <b-modal id="modal-delete" size="sm" title="">
                ¿Seguro que desea eliminar el registro?
                <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="ok()">
                    Eliminar
                </b-button>
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Cancelar
                </b-button>
                </template>
            </b-modal>
        </div>
   </div>
</template>

<script>

import NavBar from '../components/NavBar.vue'
import axios from 'axios';
export default {
    components: {
        NavBar,
    },

    name: "Users",
    data()  {
      return {
        token: '',
        loading: false,
        items: [],
        paginatedItems: [],
        itemRoles: [],
        currentPage: 1,
        perPage: 15,
        totalRows: "",
        name: '',
        email: '',
        password: '',
        rol_id: '',
        rol: '',
        update: {
            name: '',
            email: '',
            rol_id: ''
        },
      }  
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.rol = localStorage.getItem('rol');
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
        if (this.token && this.rol == 1) {
            this.getUser();
            this.getRoles();
        }else {
            this.$router.push("/");
        }
    },
    methods: {
        async getUser(){
         await axios.get('https://administracion-sitios.constru-services.com/backend/public/api/users')
            .then(response => {
                this.loading = false;
                let us = response.data.data;
                if (us.length > 0) {
                this.totalRows = us.length;
                this.paginatedItems = us;
                this.items = us;
                this.store = '';
                this.paginate(15, 0);
            } else {
                console.log("Error en la api");
            }
            });
        },
        async getRoles(){
         await axios.get('https://administracion-sitios.constru-services.com/backend/public/api/roles')
            .then(response => {
                this.loading = false;
                let us = response.data.data;
                if (us.length > 0) {
                    this.itemRoles = us;
                } else {
                    console.log("Error en la api");
                }
            });
        },
        storeUser(){
            const formData = new FormData();
            formData.append("name", this.name);
            formData.append("email", this.email);
            formData.append("password", this.password);
            formData.append("rol_id", this.rol_id);
            console.log(formData);
            this.axios.post("https://administracion-sitios.constru-services.com/backend/public/api/users", formData, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                },
            })
            .then((res) => {
                const user = res.data.data;
                console.log(user);
                this.hideModal();
                this.getUser();
            })
            .catch((error) => {
                console.error(error);
            });
        },
        updateUser(item){
            console.log(item);
            this.user_id = item.id;
            this.update.name = item.name;
            this.update.email = item.email;
            this.update.rol_id = item.rol;
            this.showModalUpdate();
        },
        updateData(){
            const formData = new FormData();
            formData.append("name", this.update.name);
            formData.append("email", this.update.email);
            formData.append("rol_id", this.update.rol_id);
            formData.append("_method", "PUT");

            this.axios.post("https://administracion-sitios.constru-services.com/backend/public/api/users/" + this.user_id, formData, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })
            .then((res) => {
                const user = res.data.data;
                console.log(user);
                this.hideModalUpdate();
                this.getUser();
            })
            .catch((error) => {
                console.error(error);
            });
            },
        paginate(page_size, page_number) {
        let itemsToParse = this.items;
        this.paginatedItems = itemsToParse.slice(
            page_number * page_size,
            (page_number + 1) * page_size
        );
        },
        onPageChanged(page) {
             this.paginate(this.perPage, page - 1);
        },
        showModal() {
            this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
        },
        toggleModal() {
            this.$root.$emit('bv::toggle::modal', 'modal-1', '#btnToggle')
        },
        showModalUpdate() {
            this.$root.$emit('bv::show::modal', 'modal-update', '#btnShow')
        },
        hideModalUpdate() {
            this.$root.$emit('bv::hide::modal', 'modal-update', '#btnShow')
        },
        toggleModalUpdate() {
            this.$root.$emit('bv::toggle::modal', 'modal-update', '#btnToggle')
        },
        showModalDelete() {
            this.$root.$emit('bv::show::modal', 'modal-delete', '#btnShow')
        },
        showMsgBoxOne(item) {
            this.boxTwo = ''
            this.$bvModal.msgBoxConfirm('¿Realmente desea eliminar el registro?', {
                title: 'Confirmación de eliminación',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Eliminar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
          .then((value) => {
            this.boxTwo = value
            const formData = new FormData();
            formData.append("_method", "DELETE" );
            this.axios.post("https://administracion-sitios.constru-services.com/backend/public/api/users/" + item.id, formData, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })
            .then((res) => {
                let prueba  = res.data.data;
                console.log(prueba);
                this.hideModal();
                this.getUser();
            })
            .catch(err => {
                console.log(err);
            })
          })
          .catch(err => {
            console.log(err);
          })
      }
    }

};

</script>

<style scoped>

</style>