import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueAxios from 'vue-axios'
import Axios from 'axios'




Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(VueAxios, Axios);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

