<template>
  <ul class="horizontal">
    <li>
      <a href="javascript:void(0)">
        <img src="https://salazarromero.com/wp-content/uploads/2021/11/Grupo-198.png" alt=""  style="width: 50%;max-width: 150px;"/>
        <img src="../assets/dive_logo.png" alt=""  style="width: 100%;max-width: 100px;"/>
      </a>
    </li>
    <div class="menuContainer">
        <li class="">
        <router-link to="/users" active-class="active">
          Usuarios
        </router-link>
      </li>
      <li class="">
        <router-link to="/home" active-class="active">
          Residenciales
        </router-link>
      </li>
      <li class="">
        <a @click="logout" href="javascript:void(0)" class="logout-btn">
          <i class="fas fa-power-off"></i> Cerrar sesión
        </a>
      </li>
    </div>
  </ul>
</template>



<script>
export default {
  name: "NavBar",

  methods: {
    logout(){
      localStorage.removeItem('token');
      this.$router.push("/");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Dropdown style */
.dropbtn {
  background-color: #333;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}
.logout-btn {
  background-color: rgb(151, 28, 28);
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 20px;
  transition: all .2s linear;
}
.logout-btn:hover {
  background-color: rgb(26, 6, 58);
  color: white;
}
.active{
  border-bottom: 3px solid #146C94; 
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: black;
}

/* ul Styles */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(245,246,249,1) 24%, rgba(94,129,227,1) 100%);

}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 5px;
  text-decoration: none;
  margin: 0 10px;
}

.menuContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
}
</style>
