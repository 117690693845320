import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Home from '../views/Home.vue'
import User from '../views/Users.vue'
// import Dashboard from '../views/Dashboard.vue'

import Acropoli from '../views/Acropoli.vue'
import Marsella from '../views/Marsella.vue'
import CiudadReal from '../views/CiudadReal.vue'
import Haciendas from '../views/Haciendas.vue'
import Metropoli from '../views/Metropoli.vue'
import Quintas from '../views/Quintas.vue'
import GlobalDevelopers from '../views/GlobalDevelopers.vue'
import Ecoterra from '../views/Ecoterra.vue'
import NuevaSanMiguel from '../views/NuevaSanMiguel.vue'
import HaciendasMediterraneo from '../views/HaciendasMediterraneo.vue'
import SalazarRomero from '../views/SalazarRomero.vue'
import Fenix from '../views/Fenix.vue'
import Villapanamericana from '../views/Villapanamericana.vue'
import Lailusion from '../views/Lailusion.vue'
import ErrorComponent from '../views/ErrorComponent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/users',
    name: 'User',
    component: User
  },
  // {
  //   path: '/dashboard/:name',
  //   name: 'Dashboard',
  //   component: Dashboard
  // },
  {
    path: '/acropoli',
    name: 'Acropoli',
    component: Acropoli
  },
  {
    path: '/marsella',
    name: 'Marsella',
    component: Marsella
  },
  {
    path: '/ciudadreal',
    name: 'CiudadReal',
    component: CiudadReal
  },
  {
    path: '/haciendas',
    name: 'Haciendas',
    component: Haciendas
  },
  {
    path: '/metropoli',
    name: 'Metropoli',
    component: Metropoli
  },
  {
    path: '/quintas',
    name: 'Quintas',
    component: Quintas
  },
  {
    path: '/global',
    name: 'GlobalDevelopers',
    component: GlobalDevelopers
  },
  {
    path: '/ecoterra',
    name: 'Ecoterra',
    component: Ecoterra
  },
  {
    path: '/nuevasanmiguel',
    name: 'NuevaSanMiguel',
    component: NuevaSanMiguel
  },
  {
    path: '/haciendasmediterraneo',
    name: 'HaciendasMediterraneo',
    component: HaciendasMediterraneo
  },
  {
    path: '/salazar',
    name: 'SalazarRomero',
    component: SalazarRomero
  },
  {
    path: '/fenix',
    name: 'Fenix',
    component: Fenix
  },
  {
    path: '/villapanamericana',
    name: 'Villapanamericana',
    component: Villapanamericana
  },
  {
    path: '/la-ilusion',
    name: 'la-ilusion',
    component: Lailusion
  },
  {
    path: '*',
    name: 'error404',
    component: ErrorComponent
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
