<template>
  <div>
   <NavBar></NavBar>

 
   <Gallery></Gallery>
   </div>
</template>

<script>

import NavBar from '../components/NavBar.vue'
import Gallery from '../components/Gallery.vue'
import axios from 'axios';
export default {
    components: {
        NavBar,
        Gallery
    },

    name: "Home",

    mounted() {
        let token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        axios.get('https://administracion-sitios.constru-services.com/backend/public/api/user-data')
        .then(response => {
            let user = response.data.data.rol;
            user.map((el) => {
                localStorage.setItem('rol', el.id);
            });
        });
    }

};

</script>

<style scoped>

</style>