<template>
  <div>
    <br /><br />
    <center>
      <h1>Error 404</h1>
      <h3>Página no encontrada</h3>
      <br><br>
      <button v-on:click="back" class="btn btn-success">Volver</button>
    </center>
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",

  methods: {
    back() {
      this.$router.push("/home");
    }
  },
  mounted() {
    if (localStorage.getItem("token")) {
      console.log("Página no encontrada");
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 15px;
}
</style>